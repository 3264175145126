import React from 'react';
import FormHandler from '../components/FormHandler/FormHandler';
import { Formik, Form, Field } from 'formik';
import Layout from '../components/Layout/Layout';
import FieldInfo from '../components/Formular/FieldInfo/FieldInfo';
import Button from '../components/UI/Button/Button';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'gatsby';
import Seo from '../components/SEO/SEO';

export default (props) => (
  <>
  <Layout
    headlines={["Kontakt"]}
    className="centeredPage--TextPage"
  >
    <h1>Schreib uns eine Nachricht </h1>
    <FormHandler 
      sendTo='php/sendContactMail.php'
      requiredInputs={['email', 'message', 'dsgvo']}
    >
      {({formikAttr, setClassDynamically, state, FHstyle}) => ( 
      <Formik 
        {...formikAttr}
        initialValues={{ email: '', name: '', message: '' }}
      >
        {({ isSubmitting }) => (
          <Form className={FHstyle.Form} >
            <div 
              style={{
                display: (state.dataSendSuccess ? "none" : "inherit"),
                opacity: (state.sendingData ? "0.5" : "initial")
              }}
            >
              <p>Kontaktiere uns gerne bei Fragen, Kritik, Anregungen und zu sonstigen Themen.</p>
              <p>Tiny House Hersteller, die bei uns gelistet werden möchten, erhalten{" "}
                <Link to="/hersteller-services/">hier</Link> weitere Informationen.
              </p>
            </div>
            <fieldset 
              disabled={state.sendingData} 
              // style={state.dataSendSuccess ? {display:'none'}:  null}
              style={{
                display: (state.dataSendSuccess ? "none" : "inherit"),
                opacity: (state.sendingData ? "0.5" : "initial")
              }}
            >
            
              {/* <label htmlFor="name">Dein Name</label> */}
              <FieldInfo 
              label={ {for: 'name', text:'Dein Name'} }
              className={FHstyle.hasFloatLabel}
              >
                <Field className={setClassDynamically('name')} type="text" name="name" placeholder=" "/>
              {/* <ErrorMessage name="name" component="div" /> */}
              </FieldInfo>

              <FieldInfo 
                label={ {for: 'email', text:'E-Mail*'} }
                className={FHstyle.hasFloatLabel}
              >
                <Field className={setClassDynamically('email')}  type="email" name="email" placeholder=" " />
              </FieldInfo>

              <FieldInfo 
              label={ {for: 'message', text:'Sag ein paar Worte*'} }
              className={FHstyle.hasFloatLabel}
              >
                {/* <Field className={setClassDynamically('message')}  type="text" name="message" placeholder=" " /> */}
                <Field
                  name="message"
                  render={({ field }) => (
                    <TextareaAutosize
                      {...field}
                      className={[setClassDynamically("message", this)].join(' ')}
                      placeholder=" "
                     
                    />
                  )}
                />
              </FieldInfo>
          

              <p> Zum Zwecke der Bearbeitung und damit wir dir antworten können, wird deine Anfrage bei uns gespeichert. Weiteres erfährst du
                in unserer Datenschutzerklärung.</p>
              <label className={FHstyle.labelCheckbox} >
                <Field className={setClassDynamically('dsgvo', 'checkbox')} type="checkbox" name="dsgvo" />
                <span > 
                  Ja, ich bin mit dem Inhalt der {" "}
                  <a target="_blank" href='/datenschutz/'>Datenschutzerklärung</a> einverstanden. 
                </span>
              </label>

              <Button color="black">
                <button type="submit" disabled={isSubmitting}>
                  Senden
                </button>
              </Button>
            </fieldset> 
          </Form>
        )}
      </Formik>)}
    </FormHandler>   
  </Layout>
  </>
);

export function Head() { 

  return(
    <Seo
      title = 'Konktakt | Tiny House Helden'
      meta_description =
        {'Kontaktiere uns gerne bei Fragen, Kritik, Anregungen und zu sonstigen Themen. Wir freuen uns auf deine Nachricht!'}
    />
  )
}